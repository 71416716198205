<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.new_m", {
                m: $t("message.device_y"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.name") }}
                  </span>
                  <el-form-item prop="device_name">
                    <el-input
                    :class="mode ? 'input__day' : 'input__night'"
                      size="medium"
                      v-model="form.device_name"
                      autocomplete="off"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <!-- end col -->

              <!-- end col -->

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.deviceType") }}
                  </span>
                  <el-form-item prop="device_type_id">
                    <select-deviceType
                      :size="'medium'"
                      :id="form.device_type_id"
                      :placeholder="columns.device_type_id.title"
                      v-model="form.device_type_id"
                    >
                    </select-deviceType>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.ip_address") }}
                  </span>
                  <el-form-item prop="ip_address">
                    <el-input
                    :class="mode ? 'input__day' : 'input__night'"
                      size="medium"
                      v-model="form.ip_address"
                      autocomplete="off"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.port") }}
                  </span>
                  <el-form-item prop="port">
                    <el-input
                    :class="mode ? 'input__day' : 'input__night'"
                      size="medium"
                      v-model="form.port"
                      autocomplete="off"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.status") }}
                  </span>
                  <el-form-item prop="status_id">
                    <select-status
                      :table_name="'devices'"
                      :id="form.status_id"
                      :size="'medium'"
                      :placeholder="columns.status_id.title"
                      v-model="form.status_id"
                    ></select-status>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.attendance_type") }}
                  </span>
                  <el-form-item prop="attendance_type">
                    <el-select
                      class="d-block"
                      v-model="form.attendance_type"
                      :placeholder="$t('message.attendance_type')"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                      <el-option
                        v-for="item in attendance_types"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.company") }}
                  </span>
                  <el-form-item prop="company_ids">
                    <select-company
                      :id="form.company_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="$t('message.company')"
                      v-model="form.company_ids"
                    ></select-company>
                  </el-form-item>
                </div>
              </el-col>
              
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.type") }}
                  </span>
                  <el-form-item prop="type">
                    <el-select
                      class="d-block"
                      v-model="form.type"
                      :placeholder="$t('message.type')"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                      <el-option
                        :label="'Kpp uchun'"
                        :value="'kpp'"
                      >
                      </el-option>
                      <el-option
                        :label="'Xojatxona uchun'"
                        :value="'toilet'"
                      >
                      </el-option>
                      <el-option
                        :label="'Omborxona uchun'"
                        :value="'sklad'"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
               <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.security_location") }}
                  </span>
                  <el-form-item >
                    <select-security-location
                      :id="form.security_location_id"
                      :size="'medium'"
                      :placeholder="$t('message.security_location')"
                      v-model="form.security_location_id"
                    ></select-security-location>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import attendanceTypes from "./attendance_types";
import drawer from "@/utils/mixins/drawer";
import selectDeviceType from "@/components/filters/inventory/select-deviceType";
import selectStatus from "@/components/filters/inventory/select-status";
import selectCompany from "@/components/filters/inventory/select-company";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer, attendanceTypes],
  name: "DeviceController",
  components: {
    selectDeviceType,
    selectStatus,
    selectCompany,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "device/rules",
      model: "device/model",
      columns: "device/columns",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "device/store",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
