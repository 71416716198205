export default {    
    data() {
        return {
            attendance_types: [
                { label: "Только для входа", value: "entrance" },
                { label: "Только для выхода", value: "exit" },
            ],
        }
    }
  }
